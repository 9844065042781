import React, { Component } from 'react';
import './App.css';

import GuessedWords from './Congrats/GuessedWords';
import Congrats from './Congrats/Congrats';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0
    };
  }

  render() {
    return (
      <div className="container" data-test="component-app">
        {/* <h1 data-test="counter-display">The counter is currently {this.state.counter}</h1> */}
        {/* <button data-test="increment-button" onClick={() => this.setState({ counter: this.state.counter + 1 })}>Increment counter</button>
        <button data-test="decrement-button" onClick={() => this.setState({ counter: this.state.counter - 1 })}>Decrement counter</button> */}
        <Congrats success={false}/>
        <GuessedWords guessedWords={[
          { guessedWord: 'train', letterMatchCount: 3 }
        ]}/>
      </div>
    )
  }
}

export default App;

