import React from 'react';
import PropTypes from 'prop-types';

/**
 * @function
 * @param {object} props - React props
 * @returns {JSX.Element} - Rendered Component
 */
const Congrats = (props) => {

    if(props.success) {
        return (
            <div data-test="component-congrats" clasName="alert alert-success">
                <span data-test="congrats-message">
                    Congratulations! You guessed the word!
                </span>
            </div>
        )
    } else {
        return (
            <div data-test="component-congrats"></div>
        )
    }
}

Congrats.propTypes = {
    success: PropTypes.bool.isRequired
};

export default Congrats;